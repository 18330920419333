var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.user || !_vm.user.id)?_c('div'):(_vm.user.hasPassword)?_c('p-form',{on:{"submit":_vm.onSubmit},scopedSlots:_vm._u([{key:"default",fn:function({ invalid }){return [_c('div',{staticClass:"c-row c-horizontal-center"},[_c('div',{staticClass:"c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10"},[_c('div',{staticClass:"c-row ui-mb-4"},[_c('div',{staticClass:"c-col-full"},[_c('div',{staticClass:"c-text-h3"},[_vm._v(" Cadastre sua nova senha ")]),_c('div',{staticClass:"c-text-b2 c-text-gray"},[_vm._v(" *Preenchimento obrigatório ")])])]),_c('div',{staticClass:"c-row ui-mb-4"},[_c('validation-provider',{staticClass:"c-col-full sm:c-col-1/2",attrs:{"rules":"required","name":"Senha atual","custom-messages":{ required: 'Senha atual é obrigatória' }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input-password',{attrs:{"data-cy":"currentPasswordField","label":"Senha atual*","error-message":errors[0]},model:{value:(_vm.form.currentPassword),callback:function ($$v) {_vm.$set(_vm.form, "currentPassword", $$v)},expression:"form.currentPassword"}}),_c('div',{staticClass:"c-d-flex ui-items-center ui-mt-1 sm:c-d-none"},[_c('icon',{staticClass:"ui-w-3 ui-mr-1",attrs:{"name":"info","color":"var(--c-color-gray-300)","filled":""}}),_c('span',{staticClass:"c-text-b3 c-text-gray-300"},[_vm._v(" Valide sua conta ")])],1)]}}],null,true)})],1),_c('separator',{staticClass:"sm:c-d-none"}),_c('div',{staticClass:"c-row"},[_c('validation-provider',{staticClass:"c-col-full sm:c-col",attrs:{"rules":"required|min:5","vid":"password","name":"Senha","custom-messages":{
            required: 'Senha é obrigatória',
            min: 'A senha precisa ter no mínimo 5 caracteres'
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input-password',{attrs:{"data-cy":"newPasswordField","label":"Nova Senha*","hint":"A senha deve ter, no mínimo, 5 caracteres","icon-info":"info","error-message":errors[0]},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", $$v)},expression:"form.newPassword"}})]}}],null,true)}),_c('validation-provider',{staticClass:"c-col-full sm:c-col",attrs:{"rules":"required|confirmed:password","name":"Confirmação de senha","custom-messages":{
            required: 'Confirmação de senha é obrigatória',
            confirmed: 'Senhas não são iguais'
          }},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('input-password',{attrs:{"label":"Confirmar nova senha*","error-message":errors[0]},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}})]}}],null,true)})],1),_c('separator')],1)]),_c('div',{staticClass:"c-row c-horizontal-center"},[_c('div',{staticClass:"c-col-full sm:c-col-1/4 lg:c-col-2"},[_c('c-button',{staticClass:"ui-w-full ui-mb-4 sm:ui-mb-0",attrs:{"data-cy":"updatePasswordSubmitButton","type":"submit","disabled":invalid,"loading":_vm.isSubmitting}},[_vm._v(" Salvar Alteração ")])],1),_c('div',{staticClass:"c-col-full sm:c-col-1/4 lg:c-col-2"},[_c('router-link',{attrs:{"to":{ name: 'meus-dados' }}},[_c('c-button',{staticClass:"ui-w-full",attrs:{"outline":"","disabled":_vm.isSubmitting}},[_vm._v(" Voltar sem salvar ")])],1)],1)])]}}])}):_c('div',{staticClass:"c-row c-horizontal-center"},[_c('div',{staticClass:"c-col-2 sm:c-col-6 md:c-col-12 lg:c-col-10"},[_c('div',{staticClass:"c-row ui-mb-4"},[_vm._m(0),_vm._m(1),_c('separator'),_c('div',{staticClass:"c-col-full ui-mt-4 c-d-flex"},[_c('c-button',{staticClass:"ui-mr-4",attrs:{"type":"button","loading":_vm.sendingNewPasswordEmail,"disabled":_vm.askedToSendNewPasswordEmail},on:{"click":_vm.sendNewPasswordEmail}},[_vm._v(" Enviar email para definir senha ")]),_c('router-link',{attrs:{"to":{ name: 'meus-dados' }}},[_c('c-button',{attrs:{"outline":""}},[_vm._v(" Voltar ")])],1)],1)],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-col-full"},[_c('div',{staticClass:"c-text-h3"},[_vm._v(" Cadastrar senha ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c-col-full ui-mt-2"},[_c('div',{staticClass:"c-text-h4"},[_vm._v(" Para definir a senha pela primeira vez é necessário confirmar seu email, o mesmo cadastrado na rede social usada para entrar. ")])])
}]

export { render, staticRenderFns }